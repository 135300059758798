


























































import Vue from 'vue';
import PListView from '@glittr/frontend-core/src/components/p-list-view/p-list-view.vue';
import GetSiHaDocumentationsRequestViewModel from '@/src/services/viewModel/request/SiHaDocumentation/GetSiHaDocumentationsRequestViewModel';
import SiHaDocumentationResourceViewModel from '@/src/services/viewModel/resource/SiHaDocumentationResourceViewModel';

export default Vue.extend({
  auth: true,
  data: () => ({
    isDownloading: {} as Record<string | number, boolean>,
    isLoading: false,
    errorResponse: undefined as any,
    projectList: {},
    filter: {
      siHaDocumentationStatusId: null,
      searchCriteria: '',
      dateFrom: undefined, // Vue.$date.now().format('YYYY-MM-DD'),
      dateTo: undefined,
    },
    statusItems: [
      { caption: Vue.$t('page.siHaDocumentationOverview.select.status.1'), id: 1 },
      { caption: Vue.$t('page.siHaDocumentationOverview.select.status.2'), id: 2 },
      { caption: Vue.$t('page.siHaDocumentationOverview.select.status.3'), id: 3 },
      { caption: Vue.$t('page.siHaDocumentationOverview.select.status.4'), id: 4 },
      { caption: Vue.$t('page.siHaDocumentationOverview.select.status.5'), id: 5 },
    ],
  }),
  computed: {
    columns(): any {
      return [
        { value: this.$t('page.siHaDocumentationOverview.table.projectNumber'), field: 'projectNumber', textAlign: 'right' },
        { value: this.$t('page.siHaDocumentationOverview.table.title'), field: 'title' },
        { value: this.$t('page.siHaDocumentationOverview.table.projectAddress'), field: 'addressLine' },
        { value: this.$t('page.siHaDocumentationOverview.table.client'), field: 'clientCompanyName' },
        { value: this.$t('page.siHaDocumentationOverview.table.date'), field: 'created', format: (date: string) => this.$format.localDate(date) },
        { value: this.$t('page.siHaDocumentationOverview.table.status'), field: 'siHaDocumentationStatus', format: (statusId:number) => Vue.$tEnum('page.siHaDocumentationOverview.select.status', statusId) },
        { value: this.$t('page.siHaDocumentationOverview.table.nextAnnualReview'), field: 'nextInspectionDueDate', format: (date: string) => this.$format.localDate(date) },
      ];
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.populateData();
      },
    },
  },
  methods: {
    async populateData() {
      const projectList = this.$refs.projectList as InstanceType<typeof PListView>;
      this.$debounce(() => {
        projectList.populateList();
      }, 300, this)();
    },
    async deleteDocumentation(id: number) {
      this.isLoading = true;
      try {
        const wantsDelete = await this.$dialog.confirm(this.$t('app.confirmDelete'), this.$t('app.doYouWantToDelete'));
        if (wantsDelete) {
          await this.$service.api.siHaDocumentation.deleteSiHaDocumentation(id);
          await this.populateData();
        }
      } catch (error) {
        this.$log.error(error);
        this.errorResponse = error;
      }
      this.isLoading = false;
    },
    async populateSihaProjectList(params: {query: any}) {
      const query = new GetSiHaDocumentationsRequestViewModel().fromModel({ ...params.query, ...this.filter });
      const response = await this.$service.api.siHaDocumentation.getSiHaDocumentations(query);
      return response;
    },
    gotoDetail(id : number) {
      this.$router.push(`/glaromat/il/siha/process/updateDocumentation/${id}`);
    },
    async downloadDocumentation(documentation : SiHaDocumentationResourceViewModel) {
      this.$set(this.isDownloading, documentation.id!, true);
      const response = await this.$service.api.siHaDocumentation.downloadSiHaDocumentation(documentation.id!);
      const created = this.$format.date(documentation.created!, 'YYYY-MM-DD');
      const fileName = `${created}_${documentation.title}.pdf`;
      this.$service.utils.downloadFile(fileName, response);
      this.$set(this.isDownloading, documentation.id!, false);
    },
  },
});
